.metrics-page {
  .buttons {
    margin-top: 16px;
  }
}

.select-days {
  padding-bottom: 15px;
}

.header th {
  font-weight: bold;
  color: red;
}
