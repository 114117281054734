.celebrities-page {
  padding-top: 55px;
  position: relative;

  .status-dropdown {
    position: absolute;
    top: 10px;
    right: 0;
    max-width: 200px;
  }

  .page-controls {
    padding-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .page-button {
    height: 35px;
  }

  .spacer {
    padding-right: 10px;
  }
}