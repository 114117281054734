.facebook-page {
  .buttons {
    margin-top: 16px;
  }
}

.logout {
  .container {
    display: flex;
    flex-direction: column;
  }
}