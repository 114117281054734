@import './theme//colors.scss';

html {
  height: 100%;
  width: 100%;
  color: white;
  background-color: var(--color-secondary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}

// The place for global styles
.card {
  display: block;
  max-width: 300px;

  img {
    width: 100%;
    height: auto;
  }
}

.horizontal-center {
  display: flex !important;
  justify-content: center !important;
}

.horizontal-center {
  display: flex !important;
  align-items: center !important;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.row-between {
  display: flex;
  justify-content: space-between;
}

textField {
  & fieldset {
    border-radius: 0;
  }
}

.input {
  input {
    border: 1px solid var(--color-primary) !important;
    background-color: transparent !important;

    &:-webkit-autofill {
      -webkit-box-shadow: none;
      -webkit-text-fill-color: var(--color-secondary);
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-only {
  display: none !important;
}

.desktop-only {
  display: flex !important;
}

.mt12 {
  margin-top: 12px !important;
}

@media screen and (max-width: 550px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: flex !important;
  }
}