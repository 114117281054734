.main-info {
  display: flex;
  align-items: flex-start;

  .list {
    padding: 0 20px;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .list-item {
      margin-bottom: 6px;
      align-items: center;
      flex-wrap: wrap;
    }

    .aligned-right {
      justify-content: end;
    }

    .right-space {
      margin-right: 10px;
    }
  }

  .status {
    margin-top: 7px;
  }

  .divider {
    margin-bottom: 16px;
  }

  .avatar {
    img {
      object-fit: contain;
      width: 100%;
      height: auto;
      max-height: 500px;
    }
  }

  .button-row {
    margin-top: 20px;
  }

  .link {
    text-decoration: underline;
  }
}


@media screen and (max-width: 550px) {
  .main-info {
    flex-direction: column;

    .divider {
      margin-top: 20px;
    }

    .list {
      padding: 10px 0 0;
    }

    .avatar {
      img {
        height: auto;
        width: 100%;
      }
    }

    .mobile-only {
      .status {
        margin-top: 0;
      }
    }
  }
}