.sidebar {
  a {
    text-decoration: none;
    color: unset;
  }

  .MuiDrawer-root {
    max-height: 100vh;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
  }
}